import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row
} from 'reactstrap';
import { CardCategory, PanelHeader, StatisticsCustom } from '../../components';
import Select from 'react-select';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import sum from 'lodash/sum';
import { stats } from '../../helpers/actions/finances';
import {
  dateFormatter,
  moneyFormatter,
  supervisorName
} from '../../helpers/formatters';
import { earningsMonthsChart } from './charts.jsx';
import { all as allSupervisors } from '../../helpers/actions/supervisors';
import { all as allProviders } from '../../helpers/actions/providers';
import moment from 'moment/moment';

const dateFormat = 'YYYY-MM-DD';
const dates = [
  {
    name: 'Dernier 3 Months',
    value: dateFormatter(moment().subtract(3, 'months'), dateFormat)
  },
  {
    name: 'Dernier 6 Months',
    value: dateFormatter(moment().subtract(6, 'months'), dateFormat)
  },
  {
    name: 'Dernier Year',
    value: dateFormatter(moment().subtract(1, 'years'), dateFormat)
  }
];

class Finances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastWeek: 0,
      lastMonths: 0,
      data: {},
      earningsInPeriod: [],
      allMonthsInPeriod: [],
      provider: null,
      providers: [],
      supervisor: null,
      supervisors: [],
      date: dateFormatter(moment().subtract(1, 'years'), dateFormat)
    };
  }

  searchIndex(list, date) {
    return list.findIndex(
      t =>
        t._id.month.toString() === date.format('M') &&
        t._id.year.toString() === date.format('YYYY')
    );
  }

  load() {
    let { provider, supervisor, date } = this.state,
      { dispatch } = this.props;

    dispatch(stats({ provider, supervisor, date }))
      .then(data => {
        let allMonthsInPeriod = [];
        let earningsInPeriod = [];
        let lastWeek = 0;
        let lastMonths = 0;
        let today = moment();
        let startDate = moment(date);

        while (startDate.isBefore(today)) {
          allMonthsInPeriod.push(startDate.format('MM / YYYY'));

          let index = this.searchIndex(data.devisMonths, startDate);

          if (index !== -1) {
            let earnings = 0;
            data.devisMonths[index].devis.forEach(d => {
              earnings += get(d, 'price.ttc', 0) + get(d, 'price.materials', 0);
            });

            earningsInPeriod.push(earnings);
          } else {
            earningsInPeriod.push(0);
          }

          startDate = startDate.add(1, 'month');
        }

        data.devisLastMonth.map(
          d =>
            (lastMonths +=
              get(d, 'price.ttc', 0) + get(d, 'price.materials', 0))
        );

        data.devisLastWeek.map(
          d =>
            (lastWeek += get(d, 'price.ttc', 0) + get(d, 'price.materials', 0))
        );

        this.setState({
          data,
          allMonthsInPeriod,
          earningsInPeriod,
          lastMonths,
          lastWeek
        });
      })
      .catch(e => {});
  }

  componentDidMount() {
    this.setState({ loading: true });

    let { dispatch } = this.props;

    Promise.all([
      dispatch(allProviders({ limit: 100 })),
      dispatch(allSupervisors({ limit: 1000 }))
    ])
      .then(([providers, supervisors]) => {
        this.setState(
          {
            providers: providers.data,
            supervisors: supervisors.data,
            loading: false
          },
          () => this.load()
        );
      })
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
        earningsInPeriod,
        allMonthsInPeriod,
        lastMonths,
        lastWeek,
        providers,
        supervisors,
        supervisor,
        provider,
        date
      } = this.state,
      { t } = this.props;

    let earningsChart = earningsMonthsChart(
      earningsInPeriod,
      allMonthsInPeriod,
      t
    );

    return (
      <div>
        <PanelHeader size="sm" content={<Row />} />
        <div className="content">
          <Card className="card-stats card-raised">
            <CardBody>
              <Row>
                <Col xs={12} md={3}>
                  <FormGroup>
                    <Label>
                      <Trans>Provider</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={providers.map(p => ({
                        label: p.name,
                        value: p._id
                      }))}
                      value={provider}
                      onChange={event => {
                        this.setState(
                          {
                            page: 1,
                            provider: event ? event.value : ''
                          },
                          () => this.load()
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={3}>
                  <FormGroup>
                    <Label>
                      <Trans>Supervisor</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={supervisors.map(sup => ({
                        label: supervisorName(sup),
                        value: sup._id
                      }))}
                      value={supervisor}
                      onChange={event => {
                        this.setState(
                          {
                            page: 1,
                            supervisor: event ? event.value : ''
                          },
                          () => this.load()
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <Link
                    to={`/finances/report?provider=${
                      provider ? provider : ''
                    }&supervisor=${supervisor ? supervisor : ''}&date=${date}`}
                    className="btn btn-info float-right"
                  >
                    <Trans>Report</Trans>
                  </Link>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row>
            <Col xs={12} sm={6}>
              <Card className="card-stats">
                <CardBody>
                  <StatisticsCustom
                    horizontal
                    icon="fa fa-euro"
                    iconState="success"
                    title={lastMonths}
                    subtitle={t('Last Month')}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={6}>
              <Card className="card-stats">
                <CardBody>
                  <StatisticsCustom
                    horizontal
                    icon="fa fa-euro"
                    iconState="success"
                    title={lastWeek}
                    subtitle={t('Last Week')}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col xs={10}>
                      <CardCategory>
                        <Trans>Devis approved</Trans>
                      </CardCategory>
                      <CardTitle tag="h2">
                        {moneyFormatter(sum(earningsInPeriod))}
                      </CardTitle>
                    </Col>
                    <Col xs={2}>
                      <FormGroup>
                        <Label>
                          <Trans>Period</Trans>
                        </Label>
                        <Select
                          className="primary"
                          clearable={false}
                          options={dates.map(d => ({
                            label: t(d.name),
                            value: d.value
                          }))}
                          value={date}
                          onChange={event => {
                            this.setState(
                              {
                                page: 1,
                                date: event ? event.value : ''
                              },
                              () => this.load()
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                    <Line
                      data={earningsChart.data}
                      options={earningsChart.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Finances));
