import React from "react";
// javascript plugin used to create scrollbars on windows
import {Route, Switch} from "react-router-dom";

import projectRoutes from "../../routes/projects.jsx";

class Projects extends React.Component {
    render() {
        return (
            <div className="projects_layout">
                <Switch>
                    {projectRoutes.map((prop, key) => {
                        return (
                            <Route
                                exact
                                path={`${this.props.match.url}${prop.path}`}
                                component={prop.component}
                                key={key}
                            />
                        );
                    })}
                </Switch>
            </div>
        );
    }
}

export default Projects;
