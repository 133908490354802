import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Accordion } from '../../components';
import { Link } from 'react-router-dom';
import { dateFormatter } from '../../helpers/formatters';

const Body = ({ n }) => {
  if (n.list) {
    return n.list.map((u, index) => (
      <Col
        className={'notif-body-row p-2'}
        key={'sub-notification-' + index + u.id}
        xs={12}
      >
        <Row>
          <Col xs={4} className={'text-capitalize text-left'}>
            <Trans>{u.operation}</Trans>{' '}
            {u.info && u.kind === 'Project' ? ' - ' + u.info : ''}
          </Col>
          <Col xs={4} className={'text-center'}>
            {u.userName}
          </Col>
          <Col xs={4} className={'text-right'}>
            {dateFormatter(u.createdAt)}
          </Col>
        </Row>
      </Col>
    ));
  } else {
    return <div />;
  }
};

const Title = ({ n }) => {
  if (n.kind === 'Devis') {
    return (
      <strong>
        <Link to={`/projects/${n.item.project}/devis/${n.item._id}`}>
          <Trans>{n.kind}</Trans>: {n.item.title}
        </Link>
      </strong>
    );
  }
  if (n.kind === 'Project') {
    return (
      <strong>
        <Link to={`/projects/${n.item._id}`}>
          <Trans>{n.kind}</Trans>: {n.item.name}
        </Link>
      </strong>
    );
  }
};

class UpdateCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: null,
      index: null
    };
  }

  componentWillMount() {
    let { notification, index } = this.props;
    this.setState({ notification, index });
  }

  componentWillReceiveProps({ notification, index }) {
    this.setState({ notification, index });
  }

  render() {
    let { notification } = this.state;

    return (
      <Col xs={12}>
        <Accordion
          plain
          defaultOpened={-1}
          components={[
            {
              title: <Title n={notification} />,
              text: (
                <div className={'notif-body'}>
                  <Body n={notification} />
                </div>
              )
            }
          ]}
        />
      </Col>
    );
  }
}

export default connect()(translate('translations-fr')(UpdateCard));
