import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Table
} from 'reactstrap';
import { PanelHeader } from '../../components';
import Select from 'react-select';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { stats } from '../../helpers/actions/dashboard';
import { statusColors } from '../../helpers/nomenclators';
import { statusProjectChart } from './charts.jsx';
import { supervisorName, dateFormatter } from '../../helpers/formatters';
import { all as allSupervisors } from '../../helpers/actions/supervisors';
import { all as allProviders } from '../../helpers/actions/providers';
import moment from 'moment/moment';

const dateFormat = 'YYYY-MM-DD';
const datesNotes = [
  {
    name: 'Last 7 Days',
    value: dateFormatter(moment().subtract(7, 'days'), dateFormat)
  },
  {
    name: 'Last Month',
    value: dateFormatter(moment().subtract(1, 'months'), dateFormat)
  },
  {
    name: 'Last 3 Months',
    value: dateFormatter(moment().subtract(3, 'months'), dateFormat)
  },
  {
    name: 'Last 6 Months',
    value: dateFormatter(moment().subtract(6, 'months'), dateFormat)
  },
  {
    name: 'Last Year',
    value: dateFormatter(moment().subtract(1, 'years'), dateFormat)
  }
];
const datesEvents = [
  {
    name: 'Last Year',
    value: dateFormatter(moment().subtract(1, 'years'), dateFormat)
  },
  {
    name: 'Last 6 Months',
    value: dateFormatter(moment().subtract(6, 'months'), dateFormat)
  },
  {
    name: 'Last 3 Months',
    value: dateFormatter(moment().subtract(3, 'months'), dateFormat)
  },
  {
    name: 'Last Month',
    value: dateFormatter(moment().subtract(1, 'months'), dateFormat)
  },
  {
    name: 'Last 7 Days',
    value: dateFormatter(moment().subtract(7, 'days'), dateFormat)
  },
  {
    name: 'Next 7 Days',
    value: dateFormatter(moment().add(7, 'days'), dateFormat)
  },
  {
    name: 'Next Month',
    value: dateFormatter(moment().add(1, 'months'), dateFormat)
  },
  {
    name: 'Next 3 Months',
    value: dateFormatter(moment().add(3, 'months'), dateFormat)
  },
  {
    name: 'Next 6 Months',
    value: dateFormatter(moment().add(6, 'months'), dateFormat)
  },
  {
    name: 'Next Year',
    value: dateFormatter(moment().add(1, 'years'), dateFormat)
  }
];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notesObject: {
        icons: [
          'flaticon-warning',
          'flaticon-alarm',
          'flaticon-delivery-truck'
        ],
        labels: ['Problem', 'Important', 'Delivery'],
        values: [0, 0, 0]
      },
      eventsObject: {
        icons: [
          'flaticon-delivery-truck',
          'flaticon-support',
          'flaticon-calendar',
          'flaticon-pie-chart'
        ],
        labels: ['delivery', 'construction', 'visit', 'other'],
        values: [0, 0, 0, 0]
      },
      statusProject: {
        labels: [],
        status: [],
        counts: []
      },
      provider: null,
      providers: [],
      supervisor: null,
      supervisors: [],
      dateNotes: dateFormatter(moment().subtract(7, 'days'), dateFormat),
      dateEvent: dateFormatter(moment().add(7, 'days'), dateFormat)
    };
  }

  load() {
    let {
        provider,
        supervisor,
        notesObject,
        eventsObject,
        dateNotes,
        dateEvent
      } = this.state,
      { dispatch, t } = this.props;

    let dateFrom,
      dateTo = '';

    if (moment().isAfter(dateEvent, dateFormat)) {
      dateFrom = dateEvent;
      dateTo = moment().format(dateFormat);
    } else {
      dateTo = dateEvent;
      dateFrom = moment().format(dateFormat);
    }

    dispatch(stats({ provider, supervisor, dateNotes, dateFrom, dateTo }))
      .then(data => {
        notesObject.values = notesObject.labels.map(n => {
          let index = data.notes ? data.notes.findIndex(t => t._id === n) : -1;
          return index !== -1 ? data.notes[index].count : 0;
        });

        eventsObject.values = eventsObject.labels.map(e => {
          let index = data.events
            ? data.events.findIndex(t => t._id === e)
            : -1;
          return index !== -1 ? data.events[index].count : 0;
        });

        let statusProject = {
          labels: [],
          status: [],
          counts: []
        };

        data.projectsStatus.forEach(status => {
          statusProject.labels.push(t(status._id));
          statusProject.status.push(status._id);
          statusProject.counts.push(status.count);
        });

        this.setState(ps => ({
          ...ps,
          notesObject,
          eventsObject,
          statusProject
        }));
      })
      .catch(e => {});
  }

  componentDidMount() {
    this.setState({ loading: true });

    let { dispatch } = this.props;

    Promise.all([
      dispatch(allProviders({ limit: 100 })),
      dispatch(allSupervisors({ limit: 1000 }))
    ])
      .then(([providers, supervisors]) => {
        this.setState(
          {
            providers: providers.data,
            supervisors: supervisors.data,
            loading: false
          },
          () => this.load()
        );
      })
      .catch(() => this.setState({ loading: false }));
  }

  onStatus(element) {
    let { statusProject } = this.state;
    let { history } = this.props;

    if (element.length) {
      history.push(
        `/projects/by-status/${statusProject.status[element[0]._index]}`
      );
    }
  }

  render() {
    let {
        notesObject,
        eventsObject,
        statusProject,
        providers,
        supervisors,
        supervisor,
        provider,
        dateNotes,
        dateEvent
      } = this.state,
      { t } = this.props;

    let statusChart = statusProjectChart(statusProject, statusColors, t);

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          <Card className="card-stats card-raised">
            <CardBody>
              <Row>
                <Col xs={12} md={3}>
                  <FormGroup>
                    <Label>
                      <Trans>Provider</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={providers.map(p => ({
                        label: p.name,
                        value: p._id
                      }))}
                      value={provider}
                      onChange={event => {
                        this.setState(
                          {
                            page: 1,
                            provider: event ? event.value : ''
                          },
                          () => this.load()
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={3}>
                  <FormGroup>
                    <Label>
                      <Trans>Supervisor</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={supervisors.map(sup => ({
                        label: supervisorName(sup),
                        value: sup._id
                      }))}
                      value={supervisor}
                      onChange={event => {
                        this.setState(
                          {
                            page: 1,
                            supervisor: event ? event.value : ''
                          },
                          () => this.load()
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <Link
                    to={`/dashboard/report?provider=${
                      provider ? provider : ''
                    }&supervisor=${supervisor ? supervisor : ''}`}
                    className="btn btn-info float-right"
                  >
                    <Trans>Report</Trans>
                  </Link>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row>
            <Col xs={12} sm={6} className="equal-height">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col xs={8}>
                      <CardTitle tag="h6">
                        <Trans>Notes</Trans>
                      </CardTitle>
                    </Col>
                    <Col xs={4}>
                      <Select
                        className="primary"
                        clearable={false}
                        options={datesNotes.map(d => ({
                          label: t(d.name),
                          value: d.value
                        }))}
                        value={dateNotes}
                        onChange={event => {
                          this.setState(
                            { dateNotes: event ? event.value : '' },
                            () => this.load()
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <tbody>
                      {notesObject.labels.map((label, key) => {
                        return (
                          <tr key={key}>
                            <td width="5%">
                              <span className={'mr-3'}>
                                <i
                                  className={`${notesObject.icons[key]} flaticon-danger`}
                                />
                              </span>
                            </td>
                            <td>
                              <Link
                                to={`/notes?type=${label}&provider=${
                                  provider ? provider : ''
                                }&supervisor=${
                                  supervisor ? supervisor : ''
                                }&date=${dateNotes}`}
                                style={{ color: '#9A9A9A' }}
                              >
                                <h6 className="stats-title m-0">{t(label)}</h6>
                              </Link>
                            </td>
                            <td className="text-right">
                              {notesObject.values[key]}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} sm={6} className="equal-height">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col xs={8}>
                      <CardTitle tag="h6">
                        <Trans>Events</Trans>
                      </CardTitle>
                    </Col>
                    <Col xs={4}>
                      <Select
                        className="primary"
                        clearable={false}
                        options={datesEvents.map(d => ({
                          label: t(d.name),
                          value: d.value
                        }))}
                        value={dateEvent}
                        onChange={event => {
                          this.setState(
                            { dateEvent: event ? event.value : '' },
                            () => this.load()
                          );
                        }}
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <tbody>
                      {eventsObject.labels.map((label, key) => {
                        return (
                          <tr key={key}>
                            <td width="5%">
                              <span className={'mr-3'}>
                                <i
                                  className={`${eventsObject.icons[key]} flaticon-success`}
                                />
                              </span>
                            </td>
                            <td>
                              <Link
                                to={`/events?type=${label}&provider=${
                                  provider ? provider : ''
                                }&supervisor=${
                                  supervisor ? supervisor : ''
                                }&date=${dateEvent}`}
                                style={{ color: '#9A9A9A' }}
                              >
                                <h6 className="stats-title">{t(label)}</h6>
                              </Link>
                            </td>
                            <td className="text-right">
                              {eventsObject.values[key]}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h6">
                    <Trans>Project States</Trans>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs={12} md={6}>
                      <Doughnut
                        onElementsClick={element => this.onStatus(element)}
                        data={statusChart.data}
                        options={statusChart.options}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <Table responsive>
                        <tbody>
                          {statusProject.status.map((status, key) => {
                            return (
                              <tr key={key}>
                                <td>
                                  <span className={'mr-3'}>
                                    <i
                                      className={'fa fa-square'}
                                      style={{ color: statusColors[key] }}
                                    />
                                  </span>
                                  {t(status)}
                                </td>
                                <td className="text-right">
                                  {statusProject.counts[key]}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Dashboard));
